import { useState } from 'react';
import { User } from '../api/types';
import { useLocalStorage } from './useLocalStorage';

interface HookProps {
  initialUser: User | undefined;
  initialToken: string;
}

export const LOCALE_STORAGE_USER = '@tennismaster-user';
export const LOCALE_STORAGE_TOKEN = '@tennismaster-token';

export const useAuthHandler = ({ initialUser, initialToken }: HookProps) => {
  const [user, setUser] = useState<User | undefined>(initialUser);
  const [token, setToken] = useState<string>(initialToken);

  const { setItem, removeItem } = useLocalStorage();

  const setCurrentUser = (authUser?: User) => {
    if (!authUser) {
      removeItem(LOCALE_STORAGE_USER);
      setUser(undefined);
      return;
    }
    setItem(LOCALE_STORAGE_USER, JSON.stringify(authUser));
    setUser(authUser);
  };

  const setAuthToken = (authToken?: string) => {
    if (!authToken) {
      removeItem(LOCALE_STORAGE_TOKEN);
      setToken('');
      return;
    }
    setItem(LOCALE_STORAGE_TOKEN, authToken);
    setToken(authToken);
  };

  return { currentUser: user, setCurrentUser, authToken: token, setAuthToken };
};
