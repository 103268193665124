import { createContext, PropsWithChildren, useMemo } from 'react';
import { User } from '../api/types';
import {
  LOCALE_STORAGE_TOKEN,
  LOCALE_STORAGE_USER,
  useAuthHandler,
} from '../hooks/useAuthHandler';
import { useLocalStorage } from '../hooks/useLocalStorage';

interface AuthContextProps {
  currentUser: User | undefined;
  setCurrentUser: (user: User | undefined) => void;
  setAuthToken: (token: string | undefined) => void;
  logout: VoidFunction;
}

export const AuthContext = createContext<AuthContextProps>({
  currentUser: undefined,
  setCurrentUser: () => {},
  setAuthToken: () => {},
  logout: () => {},
});

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { getItem } = useLocalStorage();

  const initUser = getItem(LOCALE_STORAGE_USER);
  const initialToken = getItem(LOCALE_STORAGE_TOKEN) || ''; // FIXME: Remove this

  const { currentUser, setCurrentUser, setAuthToken } = useAuthHandler({
    initialUser: initUser ? JSON.parse(initUser) : undefined,
    initialToken,
  });

  const logUserOut = () => {
    setCurrentUser(undefined);
    setAuthToken(undefined);
  };

  const values = useMemo(
    () => ({
      currentUser,
      setCurrentUser,
      setAuthToken,
      logout: logUserOut,
    }),
    [currentUser]
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
