import 'react-quill/dist/quill.snow.css';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/AuthContext';
// import { AppProvider } from './contexts/AppContext';
import ComingSoon from './pages/ComingSoon';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  <AuthProvider>
    {/* <AppProvider> */}
    <ComingSoon />
    {/* </AppProvider> */}
  </AuthProvider>
  // </React.StrictMode>
);

reportWebVitals();
